<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  [maxHeight]="maxHeight"
  (onResizeEnd)="onResizeEnd()"
  [resizeEnabled]="true"
  [fullScreen]="fullScreen"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-brand big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="box-type-document yellowSignature">
      <p>PK</p>
      <p class="p_document_name">
        {{ "deliveries.title" | translate }}
      </p>
    </div>
    <div
      class="text-right title-pop-up"
      style="display: flex; justify-content: flex-end; align-items: center"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
      *ngIf="event.deviceType != 'mobile'; else mobileHeaderTemplate"
    >
      <app-panel-mode-switch
        [(mode)]="panelMode"
        (modeChange)="onPanelModeChange($event)"
      >
      </app-panel-mode-switch>

      <div class="separator-new" style="margin-right: 12px"></div>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>

      <div class="separator-new" style="margin-right: 12px"></div>

      <app-print-settings
        componentName="addCrmDelivery"
        [dropDownMode]="true"
        [splitButton]="false"
        style="margin-right: 6px"
        [ngStyle]="{ display: mode !== 'add' ? 'none' : 'block' }"
        [refreshPrintSettings]="refreshPrintSettings"
        [isVisible]="mode === 'add'"
      >
      </app-print-settings>

      <dx-button
        [icon]="
          'icon ' +
          (fullScreen
            ? 'absui-icon--fullscreen-off'
            : 'absui-icon--fullscreen2')
        "
        class="btn-dx-top-menu"
        type="default"
        stylingMode="text"
        [id]="'fitToWidth' + unicalGuid"
        (click)="fullScreen = !fullScreen; onResizeEnd()"
      ></dx-button>
      <div class="separator-new"></div>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'save' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="showDialogOnCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#save' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#fitToWidth' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "servicesDevices.fitToWidth" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      #scrollView
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 45px)'"
      direction="vertical"
    >
      <form [formGroup]="form">
        <dx-tab-panel
          #tabPanel
          [(selectedIndex)]="selectedTab"
          [items]="tabs"
          [loop]="false"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          *ngIf="panelMode === 'tabs'"
          class="tab-panel-document mt5px"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div
                *ngSwitchCase="'crmDeliveries.packageInformation' | translate"
              >
                <ng-container *ngTemplateOutlet="tab1"></ng-container>
              </div>
              <div *ngSwitchCase="'crmDeliveries.receiverData' | translate">
                <ng-container *ngTemplateOutlet="tab2"></ng-container>
              </div>

              <div *ngSwitchCase="'crmDeliveries.senderData' | translate">
                <ng-container *ngTemplateOutlet="tab3"></ng-container>
              </div>
              <div
                *ngSwitchCase="
                  'crmDeliveries.additionalInformation' | translate
                "
              >
                <ng-container *ngTemplateOutlet="tab4"></ng-container>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>

        <dx-accordion
          *ngIf="panelMode === 'accordion'"
          #accordion
          [dataSource]="accordionTabs"
          [collapsible]="true"
          [multiple]="true"
          [animationDuration]="250"
          [(selectedItems)]="selectedItems"
          [(selectedIndex)]="selectedTab"
        >
          <div *dxTemplate="let tab of 'title'" #accordionTitle>
            {{ tab.label }}
          </div>
          <div *dxTemplate="let item of 'item'">
            <ng-container [ngSwitch]="item.value">
              <!-- style="padding-bottom: 24px;" -->
              <div *ngSwitchCase="0" #accordionContent>
                <ng-container *ngTemplateOutlet="tab1"></ng-container>
              </div>
              <!-- style="padding-bottom: 24px;" -->
              <div *ngSwitchCase="1" #accordionContent>
                <ng-container *ngTemplateOutlet="tab2"></ng-container>
              </div>
              <!-- style="padding-bottom: 24px;" -->
              <div *ngSwitchCase="2" #accordionContent>
                <ng-container *ngTemplateOutlet="tab3"></ng-container>
              </div>
              <!--  style="padding-bottom: 370px;" -->
              <div *ngSwitchCase="3" #accordionContent>
                <ng-container *ngTemplateOutlet="tab4"></ng-container>
              </div>
            </ng-container>
          </div>
        </dx-accordion>

        <!-- <div> -->
        <!-- <div
          class="text-center"
          style="
            width: 72px;
            display: flex;
            flex-direction: column;
            align-items: start;
            padding-top: 10px;
            padding-left: 20px;
          "
        >
          <div
            class="text-center"
            style="width: 12px; height: 30px"
            *ngFor="let tab of accordionTabs; let index = index"
          >
            <div
              class="icon-accordion"
              [id]="'nav-accordion-' + tab.value"
              [ngClass]="{ selected: isTabHighlited(index) }"
              (click)="toggleTab(index)"
            ></div>
            <dx-tooltip
              [target]="'#nav-accordion-' + tab.value"
              hideEvent="dxhoverend"
              position="left"
              [hideOnOutsideClick]="false"
              [showEvent]="event.tooltipShowEvent"
            >
              <div *dxTemplate="let data of 'content'">
                {{ tab.label }}
              </div>
            </dx-tooltip>
          </div>
        </div> -->

        <!-- <div style="width: 100%;">
                        <div [style.height]="accordionHeight">
                            <dx-scroll-view #scrollView width="100%" height="100%" direction="vertical" (onScroll)="onScroll($event)">

                            </dx-scroll-view>
                        </div>
                    </div> -->
        <!-- </div> -->

        <ng-template #tab1>
          <div class="form-columns">
            <div class="form-column form-column__left">
              <div class="form-column__header">Operator</div>

              <!-- Operator -->
              <div class="form-column__item">
                <label style="width: 160px">Operator</label>
                <div class="form-control">
                  <dx-select-box
                    [readOnly]="readOnly"
                    (onValueChanged)="operatorChanged($event)"
                    formControlName="DeliveryOperatorCode"
                    valueExpr="DeliveryOperatorCode"
                    displayExpr="Name"
                    [items]="operators"
                    [ngClass]="{
                      'input-required':
                        !form.controls.DeliveryOperatorCode.valid && submitted
                    }"
                  ></dx-select-box>
                </div>
              </div>

              <!-- Kurier -->
              <div class="form-column__item">
                <label style="width: 160px">{{
                  "crmDeliveries.courier" | translate
                }}</label>
                <div class="form-control">
                  <dx-select-box
                    [readOnly]="readOnly"
                    formControlName="CourierId"
                    valueExpr="DeliveryCourierId"
                    displayExpr="Name"
                    (onValueChanged)="courierChanged($event)"
                    [items]="filteredCouriers"
                  ></dx-select-box>
                </div>
              </div>

              <!-- Wielkość -->

              <div class="form-column__item">
                <label>{{ "crmDeliveries.size" | translate }}</label>
                <div class="form-control">
                  <dx-select-box
                    [readOnly]="readOnly"
                    formControlName="PackageSize"
                    valueExpr="Value"
                    displayExpr="Value"
                    [items]="courierSizes"
                  ></dx-select-box>
                </div>
              </div>
            </div>

            <div class="form-column form-column__right">
              <div class="form-column__header">
                {{ "crmDeliveries.dates" | translate }}
              </div>

              <!-- Data nadania -->
              <div class="form-column__item">
                <label>{{ "crmDeliveries.postingDate" | translate }}</label>
                <div class="form-control">
                  <wapro-date-box
                    formControlName="ShipmentDate"
                    [readOnly]="readOnly"
                  ></wapro-date-box>
                </div>
              </div>

              <!-- Data odbioru -->
              <div class="form-column__item">
                <label>{{ "crmDeliveries.receiveDate" | translate }}</label>
                <div class="form-control">
                  <wapro-date-box
                    formControlName="ReceiveDate"
                    [readOnly]="readOnly"
                  ></wapro-date-box>
                </div>
              </div>

              <!-- Data zwrotu -->
              <div class="form-column__item">
                <label>{{ "crmDeliveries.returnDate" | translate }}</label>
                <div class="form-control">
                  <wapro-date-box
                    formControlName="ReturnDate"
                    [readOnly]="readOnly"
                  ></wapro-date-box>
                </div>
              </div>
            </div>
            
            <div class="form-column form-column__left">
              <div class="form-column__header">
                {{ "crmDeliveries.packageDetails" | translate }}
              </div>

              <!-- Pobranie -->
              <div class="form-column__item">
                <label>{{ "crmDeliveries.pickingUp" | translate }}</label>
                <div class="form-control">
                  <dx-number-box
                    [readOnly]="readOnly"
                    formControlName="CashOnDeliveryValue"
                    [min]="0"
                    [format]="'#0.00'"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                  ></dx-number-box>
                </div>
              </div>

              <!-- Ubezpieczenie -->
              <div class="form-column__item">
                <label>{{ "crmDeliveries.insurance" | translate }}</label>
                <div class="form-control">
                  <dx-number-box
                    [readOnly]="readOnly"
                    formControlName="InsuranceValue"
                    [min]="0"
                    [format]="'#0.00'"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                  ></dx-number-box>
                </div>
              </div>

              <!-- Kod nadania -->
              <div class="form-column__item">
                <label>{{ "deliveries.shipmentCode" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ShipmentCode"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Zawartość przesyłki -->
              <div class="form-column__item">
                <label
                  >{{ "deliveries.packageContains" | translate }}
                  <strong>*</strong></label
                >
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReferenceCode"
                    [ngClass]="{
                      'input-required':
                        !form.controls.ReferenceCode.valid && submitted
                    }"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Nr paczki -->
              <div class="form-column__item">
                <label>{{ "deliveries.packageNumber" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="PackageNumber"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Duża paczka -->
              <div class="form-column__item">
                <div class="form-control">
                  <dx-check-box
                    text="{{ 'deliveries.outsized' | translate }}"
                    formControlName="IsOutsize"
                    [readOnly]="readOnly"
                  ></dx-check-box>
                </div>
              </div>

              <!-- Paczka nietypowa -->
              <div class="form-column__item">
                <div class="form-control">
                  <dx-check-box
                    text="{{ 'deliveries.unusual' | translate }}"
                    formControlName="IsUnusual"
                    [readOnly]="readOnly"
                  ></dx-check-box>
                </div>
              </div>
            </div>

            <div class="form-column form-column__right">
              <div class="form-column__header">
                {{ "crmDeliveries.packageDimensions" | translate }}
              </div>

              <!-- Długość -->
              <div class="form-column__item">
                <label
                  >{{ "deliveries.length" | translate }}
                  <strong>*</strong></label
                >
                <div class="form-control">
                  <dx-number-box
                    [readOnly]="readOnly"
                    formControlName="DimensionA"
                    [min]="0"
                    [step]="5"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    [ngClass]="{
                      'input-required':
                        !form.controls.DimensionA.valid && submitted
                    }"
                  ></dx-number-box>
                </div>
              </div>

              <!-- Szerokość -->
              <div class="form-column__item">
                <label
                  >{{ "articles.width" | translate }} <strong>*</strong></label
                >
                <div class="form-control">
                  <dx-number-box
                    [readOnly]="readOnly"
                    formControlName="DimensionB"
                    [min]="0"
                    [step]="5"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    [ngClass]="{
                      'input-required':
                        !form.controls.DimensionB.valid && submitted
                    }"
                  ></dx-number-box>
                </div>
              </div>

              <!-- Wysokość -->
              <div class="form-column__item">
                <label
                  >{{ "articles.height" | translate }} <strong>*</strong></label
                >
                <div class="form-control">
                  <dx-number-box
                    [readOnly]="readOnly"
                    formControlName="DimensionC"
                    [min]="0"
                    [step]="5"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    [ngClass]="{
                      'input-required':
                        !form.controls.DimensionC.valid && submitted
                    }"
                  ></dx-number-box>
                </div>
              </div>

              <!-- Waga -->
              <div class="form-column__item">
                <label
                  >{{ "articles.weight" | translate }} <strong>*</strong></label
                >
                <div class="form-control">
                  <dx-number-box
                    [readOnly]="readOnly"
                    formControlName="Weight"
                    [min]="0.0"
                    [format]="'#0.00'"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    [ngClass]="{
                      'input-required': !form.controls.Weight.valid && submitted
                    }"
                  ></dx-number-box>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #tab2>
          <div class="form-columns">
            <div class="form-column form-column__left">
              <div class="form-column__header">
                {{ "crmDeliveries.contractorData" | translate }}
              </div>

              <!-- Nazwa -->
              <div class="form-column__item">
                <label
                  >{{ "expansionSlote.form.name" | translate }}
                  <strong>*</strong></label
                >
                <div class="form-control">
                  <app-contractors
                    [selectCustomerShortcut]="selectCustomerShortcut"
                    (onSelectCustomerShortcut)="
                      onSelectCustomerShortcut($event)
                    "
                    [dropDownBoxMode]="true"
                    [selectedId]="form.get('CustomerId').value"
                    (onChoosed)="onCustomerIdChoosed($event)"
                    [className]="
                      !form.controls.CustomerId.valid && submitted
                        ? true
                        : false
                    "
                    [readOnly]="readOnly"
                  ></app-contractors>
                </div>
              </div>

              <!-- Email -->
              <div class="form-column__item">
                <label
                  >{{ "companies.form.addressDetail.email" | translate
                  }}<strong>*</strong></label
                >
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    [ngClass]="{
                      'input-required':
                        !form.controls.ReceiverEmail.valid && submitted
                    }"
                    formControlName="ReceiverEmail"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Telefon -->
              <div class="form-column__item">
                <label
                  >{{ "companies.form.phone" | translate }}
                  <strong>*</strong></label
                >
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverPhone"
                    [ngClass]="{
                      'input-required':
                        !form.controls.ReceiverPhone.valid && submitted
                    }"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Login Allegro -->
              <div class="form-column__item">
                <label>Login allegro</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="AllegroLogin"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Id transakcji allegro -->
              <div class="form-column__item">
                <label>{{ "deliveries.allegroId" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="AllegroId"
                  ></dx-text-box>
                </div>
              </div>

              <div class="form-column__header">
                {{ "crmDeliveries.receiverData" | translate }}
              </div>

              <div class="form-column__item">
                <div class="form-control">
                  <dx-radio-group
                    [items]="deliveryTypes"
                    layout="vertical"
                    [(value)]="deliveryTypeValue"
                    [readOnly]="readOnly"
                    displayExpr="label"
                    valueExpr="value"
                  ></dx-radio-group>
                </div>
              </div>
              <div class="form-column__item" *ngIf="deliveryTypeValue === 0">
                <label>{{ "crmDeliveries.choosePoint" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [elementAttr]="{ class: 'txtbot-as-dropdown-text' }"
                    formControlName="ReceivePointCode"
                    [readOnly]="true"
                  ></dx-text-box>
                  <dx-button
                    [elementAttr]="{ class: 'btn-as-dropdown-icon h-28-i' }"
                    icon="icon absui-icon--dropdown--folder-expand"
                    type="normal"
                    (onClick)="showReceivePointCode = true"
                    style="height: 28px !important"
                    [disabled]="readOnly"
                  ></dx-button>
                </div>
              </div>
            </div>
            <div class="form-column form-column__right">
              <div class="form-column__header">
                {{ "crmDeliveries.receiverAddress" | translate }}
              </div>

              <!-- radio firmowy  prywatny -->
              <div class="form-column__item">
                <div class="form-control">
                  <dx-radio-group
                    [items]="addressTypes"
                    layout="vertical"
                    [(value)]="addressTypeValue"
                    [readOnly]="readOnly"
                    displayExpr="label"
                    valueExpr="value"
                  ></dx-radio-group>
                </div>
              </div>

              <!-- Pobierz domyślny adres dostawy -->
              <div class="form-column__item">
                <div class="form-control">
                  <dx-button
                    height="28"
                    class="btn-extra"
                    [disabled]="readOnly"
                    text="{{ 'deliveries.getDefaultAddress' | translate }}"
                    (onClick)="getDefaultAddress()"
                    style="flex: 0 1 fit-content"
                  ></dx-button>
                </div>
              </div>

              <!-- Odbiorca -->
              <div class="form-column__item">
                <label>{{ "constractors.recipient" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverCompany"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Imię -->
              <div class="form-column__item">
                <label>{{ "start.userInfo.firstName" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverFirstName"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Nazwisko -->
              <div class="form-column__item">
                <label>{{ "start.userInfo.lastName" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverLastName"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Kraj -->
              <div class="form-column__item">
                <label>{{
                  "bankAccounts.grid.columns.country" | translate
                }}</label>
                <div class="form-control">
                  <app-country-box
                    [advancedChooser]="true"
                    [selectedCountry]="form.get('CountryCode').value"
                    (onChoosed)="selectedCountryChange($event)"
                    [readOnly]="readOnly"
                  >
                  </app-country-box>
                </div>
              </div>

              <!-- Kod pocztowy -->
              <div class="form-column__item">
                <label>{{ "companies.form.postalCode" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverPostalCode"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Miasto -->
              <div class="form-column__item">
                <label>{{ "companies.form.city" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverCity"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Ulica -->
              <div class="form-column__item">
                <label>{{ "companies.form.street" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverStreet"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Nr budynku -->
              <div class="form-column__item">
                <label>{{ "deliveries.buildingNo" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverBuildingNumber"
                  ></dx-text-box>
                </div>
              </div>

              <!-- Nr lokalu -->
              <div class="form-column__item">
                <label>{{
                  "companies.form.addressDetail.localNumber" | translate
                }}</label>
                <div class="form-control">
                  <dx-text-box
                    [readOnly]="readOnly"
                    formControlName="ReceiverLocalNumber"
                  ></dx-text-box>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #tab3>
          <div class="form-columns">
            <div class="form-column form-column__left">
              <div class="form-column__header">
                {{ "crmDeliveries.senderData" | translate }}
              </div>

              <!-- radio miejsce odbioru: kurier odbiera u nadawcy / nadanie w punkcie -->
              <div class="form-column__item">
                <div class="form-control">
                  <dx-radio-group
                    [items]="sendingTypes"
                    layout="vertical"
                    [ngClass]="{ 'no-padding': event.deviceType == 'mobile' }"
                    [readOnly]="readOnly"
                    displayExpr="label"
                    formControlName="ReceiveCourier"
                    valueExpr="value"
                    (onValueChanged)="sendingTypesChange($event)"
                  ></dx-radio-group>
                </div>
              </div>

              <ng-container *ngIf="form.get('ReceiveCourier').value">
                <!-- Od -->
                <div class="form-column__item">
                  <label>{{ "dateRange.from" | translate }}</label>
                  <div class="form-control">
                    <wapro-date-box
                      type="datetime"
                      formControlName="CourierReceiveDateStart"
                    ></wapro-date-box>
                  </div>
                </div>

                <!-- Do -->
                <div class="form-column__item">
                  <label>{{ "dateRange.to" | translate }}</label>
                  <div class="form-control">
                    <wapro-date-box
                      width="290"
                      type="datetime"
                      formControlName="CourierReceiveDateEnd"
                    ></wapro-date-box>
                  </div>
                </div>
              </ng-container>
              <div
                class="form-column__item"
                *ngIf="!form.get('ReceiveCourier').value"
              >
                <label>{{ "crmDeliveries.choosePoint" | translate }}</label>
                <div class="form-control">
                  <dx-text-box
                    class="choosePoint"
                    [elementAttr]="{ class: 'txtbot-as-dropdown-text' }"
                    formControlName="ShipmentPointCode"
                    [readOnly]="true"
                    [ngClass]="{
                      'input-required':
                        !form.controls.ShipmentPointCode.valid && submitted
                    }"
                  ></dx-text-box>
                  <dx-button
                    [elementAttr]="{
                      class: 'btn-as-dropdown-icon h-28-i mobile'
                    }"
                    icon="icon absui-icon--dropdown--folder-expand"
                    type="normal"
                    (onClick)="showShipmentPointCode = true"
                    [disabled]="readOnly"
                  ></dx-button>
                </div>
              </div>
              <!--    ,
                                "receiveAtPoint": "Receive at point",
                                "courierDelivery": "Courier delivers the recipient",
                                "courierReceive": "Courier receives at sender",
                                "sendAtPoint": "Send at point"
                            -->
            </div>
            <div
              class="form-column form-column__right"
              *ngIf="form.get('ReceiveCourier').value"
            >
              <div class="form-column__header">
                {{ "crmDeliveries.sendAddress" | translate }}
              </div>
              <div class="form-column__item">
                <!-- Adres nadania -->
                <div class="form-control form-control__full-width">
                  <div>
                    <app-delivery-address
                      type="nadanie"
                      [isVisible]="true"
                      [title]="'deliveryAddress.title' | translate"
                      [readOnly]="readOnly"
                      [selectedId]="form.get('ReceiveAddressId').value"
                      [operatorCode]="form.get('DeliveryOperatorCode').value"
                      (onClosed)="showAddress = false; cd.detectChanges()"
                      (onChoosed)="
                        onAddressChoosed($event); showAddress = false
                      "
                      [isPopupMode]="showAddress"
                      [ngClass]="{
                        'input-required':
                          !form.controls.ReceiveAddressId.valid && submitted
                      }"
                    ></app-delivery-address>
                    <input
                      class="d-none"
                      width="255"
                      [readOnly]="true"
                      hidden="true"
                      formControlName="ReceiveAddressId"
                      [ngClass]="{
                        'input-required':
                          !form.controls.ReceiveAddressId.valid && submitted
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #tab4>
          <div class="form-columns">
            <div class="form-column form-column__left">
              <div class="form-column__item">
                <label>{{ "crmDeliveries.returnAddress" | translate }}</label>
                <div class="form-control">
                  <app-delivery-address
                    type="zwrot"
                    [isVisible]="true"
                    [title]="'deliveryAddress.receiveTitle' | translate"
                    [readOnly]="readOnly || !deliveryOperator?.HasReturnAddres"
                    [selectedId]="form.get('ReturnAddressId').value"
                    [operatorCode]="form.get('DeliveryOperatorCode').value"
                    (onClosed)="showReturnPointCode = false; cd.detectChanges()"
                    (onChoosed)="onReturnAddressChoosed($event)"
                    [isPopupMode]="showReturnPointCode"
                  ></app-delivery-address>
                </div>
              </div>
            </div>

            <div class="form-columns__separator"></div>
            <!-- 
            
                    style="
                      width: 290px;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;
                      align-items: flex-end;
                    "
                     -->
            <div class="form-column form-column__right">
              <div class="form-column__item">
                <label>{{
                  "crmDeliveries.additionalInformation" | translate
                }}</label>
                <div class="form-control">
                  <dx-text-area
                    formControlName="Details"
                    minHeight="121"
                  ></dx-text-area>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </form>
    </dx-scroll-view>

    <div *ngIf="event.deviceType == 'mobile'">
      <ng-container *ngTemplateOutlet="mobileBottomTemplate"></ng-container>
    </div>
  </div>
</dx-popup>

<app-delivery-points
  [isVisible]="showShipmentPointCode"
  [readOnly]="readOnly"
  [isPopupMode]="true"
  [selectedId]="form.get('ShipmentPointCode').value"
  (onClosed)="showShipmentPointCode = false; cd.detectChanges()"
  (onChoosed)="onShipmentPointCodeChoosed($event)"
></app-delivery-points>

<app-delivery-points
  [isVisible]="showReceivePointCode"
  [readOnly]="readOnly"
  [isPopupMode]="true"
  [selectedId]="form.get('ReceivePointCode').value"
  (onClosed)="showReceivePointCode = false; cd.detectChanges()"
  (onChoosed)="onReceivePointCodeChoosed($event)"
></app-delivery-points>

<!-- <app-delivery-address
  type="zwrot"
  [isVisible]="showReturnPointCode"
  [title]="'deliveryAddress.receiveTitle' | translate"
  [readOnly]="readOnly"
  [selectedId]="form.get('ReturnAddressId').value"
  [operatorCode]="form.get('DeliveryOperatorCode').value"
  (onClosed)="showReturnPointCode = false; cd.detectChanges()"
  (onChoosed)="onReturnAddressChoosed($event)"
></app-delivery-address> -->

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || selectCustomerShortcut"
></wapro-keyboard-shortcuts>

<!-- mobile template -->

<ng-template #mobileHeaderTemplate>
  <div class="header-search-mobile">
    <i
      class="icon absui-icon--close"
      style="position: absolute; top: 14px; right: 16px; z-index: 1"
      (click)="isVisible = false"
    ></i>
  </div>
</ng-template>

<ng-template #mobileBottomTemplate>
  <div class="bottom-mobile-form">
    <!-- <dx-button class="btn-dx-top-menu-folder" icon="icon absui-icon--more-horiz" style="float: left;" (onClick)="isMobileSettings = true"></dx-button> -->

    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 12px"
      (onClick)="onSave()"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
    ></dx-button>
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="onCancel()"
      [id]="'cancel' + unicalGuid"
    ></dx-button>
  </div>

  <app-mobile-list
    [dataSource]="mobileMenuFooter"
    [visible]="isMobileSettings"
    (onClosed)="isMobileSettings = false"
    (onItemClick)="clickOptions($event)"
  ></app-mobile-list>
</ng-template>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'add-crm-delivery'"
></app-user-guide>

<app-confirm-dialog
  *ngIf="isCancelPackage"
  [isVisible]="isCancelPackage"
  (onRemoving)="onCancel()"
  (onClosing)="isCancelPackage = false"
  confirmHeader="crmDeliveries.cancellation"
  confirmText="crmDeliveries.cancellationMessage"
></app-confirm-dialog>
