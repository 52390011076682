import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  DxAccordionComponent,
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabPanelComponent,
  DxTabPanelModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { AppServices } from 'src/app/app-services.service';
import { PrintConfig } from 'src/app/core/print-settings/print-config-storage-data.model';
import { SingleRecordMode } from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { DeliveryAddressComponent } from 'src/app/libraries/crm/delivery-address/delivery-address.component';
import { PrintService } from 'src/app/print.service';
import { HelpService } from '../../../help-service.service';
import { IDeliveryOperationParameter } from './IDeliveryOperationParameter';
import {
  IDeliveryCourier,
  IDeliveryOperator,
  ValueExpr,
} from './delivery.model';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';
import { CommonModule } from '@angular/common';
import { LibrariesModule } from 'src/app/libraries/libraries.module';
import { ConfirmDialogComponent } from 'src/app/core/confirm-dialog/confirm-dialog.component';
import { CoreModule } from 'src/app/core/core.module';
import { WaproDateBoxComponent } from 'src/app/core/wapro-date-box/wapro-date-box.component';
import { MobileListComponent } from 'src/app/core/mobile-list/mobile-list.component';
import { NgShortcutsComponent } from 'src/app/core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { PanelMode } from 'src/app/core/panel-mode-switch/panel-mode-switch.interface';

@Component({
    selector: 'app-add-crm-delivery',
    templateUrl: './add-crm-delivery.component.html',
    styleUrls: ['./add-crm-delivery.component.scss'],
    inputs: [
        'isVisible',
        'isCopy',
        'readOnly',
        'selectedId',
        'mode',
        'objectToDelivery',
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DxAccordionModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxTooltipModule,
        DxPopupModule,
        DxScrollViewModule,
        DxTabPanelModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxTextBoxModule,
        DxTextAreaModule,
        LibrariesModule,
        ConfirmDialogComponent,
        CoreModule,
        WaproDateBoxComponent,
        DxRadioGroupModule,
        MobileListComponent,
        NgShortcutsComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        FormGroupDirective,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeliveryAddressComponent),
            multi: true,
        },
    ]
})
export class AddCrmDeliveryComponent implements OnInit {
  objectToDelivery: any;
  mode: SingleRecordMode = null;
  panelMode: PanelMode = 'tabs';
  selectedItems = [];
  highlightedItems = [0];
  accordionTabs = [
    { value: 0, label: 'Informacje o przesyłce' },
    { value: 1, label: 'Dane odbiorcy' },
    { value: 2, label: 'Dane nadawcy' },
    { value: 3, label: 'Informacje dodatkowe' },
  ];
  readOnly = false;
  @ViewChildren('accordionTitle') accordionTitle: QueryList<HTMLElement>;
  @ViewChildren('accordionContent') accordionContent: QueryList<HTMLElement>;
  @ViewChild('accordion') accordion: DxAccordionComponent;
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  fullScreen: boolean = false;
  isCopy: boolean = false;
  visibleUserExtensions: boolean = false;
  isReminder: boolean = false;
  calculatePriceType = 'price';
  selectedTab = 0;
  widthWindow: string | number | Function = '75%';
  heightWindow: string | number | Function = '90%';
  selectedId: number = null;
  isVisible = false;
  submitted = false;
  form: FormGroup;
  shortcuts: ShortcutInput[] = [];
  isMobileSettings: boolean = false;
  addressTypes = [
    { label: 'Firmowy', value: 0 },
    { label: 'Prywatny', value: 1 },
  ];
  changes: SimpleChanges = null;
  isCancelPackage: boolean = false;

  mobileMenuFooter = [{ id: 0, name: 'Pola dodatkowe' }];

  deliveryTypes = [
    { label: 'Odbiór w punkcie', value: 0 },
    { label: 'Kurier dostarcza odbiorcy', value: 1 },
  ];

  sendingTypes = [
    { label: 'Kurier odbiera u nadawcy', value: true, disabled: true },
    { label: 'Nadanie w punkcie', value: false, disabled: false },
  ];

  scrollPosition = 0;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  courierSizes: ValueExpr[] = [];
  operators: IDeliveryOperator[] = [];
  couriers: IDeliveryCourier[] = [];
  filteredCouriers: IDeliveryCourier[] = [];
  minWidth;
  maxHeight;
  tabs = [];
  localStorageData = {
    skipUserGuide: false,
  };
  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  defaultOperator: IDeliveryOperator;
  selectCustomerShortcut = false;
  title;
  refreshPrintSettings: boolean;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public print: PrintService,
    private helpService: HelpService,
    private printSettings: PrintSettingsService
  ) {
    this.getTransaltions();
    this.createForm();
    this.translate
      .get('menu.user-extensions')
      .subscribe((text) => (this.mobileMenuFooter[0].name = text));
    this.widthWindow = this.event.setWidthPopUp();
    this.minWidth = this.event.setMinWidthPopUp();
    this.maxHeight = this.event.setMaxHeight;
    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    if (localStorage.getItem('modeAccordionCrmDeliveries')) {
      this.panelMode =
        localStorage.getItem('modeAccordionCrmDeliveries') == 'false'
          ? 'tabs'
          : 'accordion';
    }
  }

  clickOptions = (e) => {
    if (e.itemData.id == 0 && !this.readOnly) {
      this.visibleUserExtensions = true;
    }
  };

  disableInactiveOperator = (operator) => {
    if (!operator.IsActive) {
      operator.disabled = true;
    }
    return operator;
  };

  getOperators = () => {
    return new Promise((resolve) => {
      this.appService.getAuth(`deliveries/operators`).subscribe({
        next: (res) => {
          this.operators = res.data;
          this.operators.forEach((operator) => {
            this.disableInactiveOperator(operator);
          });
          this.getCouriers();
          resolve(true);
        },
        error: (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
          localStorage.removeItem('crmDeliveryOperators');
        },
      });
    });
  };

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
    const positions = [];
    this.accordionTitle.forEach((el) => {
      const bounds =
        (el as any)?.nativeElement?.getBoundingClientRect() || null;
      positions.push(bounds);
    });

    if (positions[1]?.y >= 250) {
      this.highlightedItems = [0];
    } else if (positions[2]?.y >= 250) {
      this.highlightedItems = [1];
    } else if (positions[3]?.y >= 250) {
      this.highlightedItems = [2];
    } else {
      this.highlightedItems = [3];
    }
    // if(positions[0]?.top >= 0){
    //   this.highlightedItems = [0]
    // }
    // else if(positions[1]?.top >= 0){
    //   this.highlightedItems = [1]
    // }
    // else if(positions[2]?.top >= 0 && positions[2]?.top > positions[2]?.height){
    //   this.highlightedItems = [2]
    // }
    // else if(positions[3]?.top >= 0 && positions[3]?.top > positions[3]?.height){
    //   this.highlightedItems = [3]
    // }
  };

  getCouriers() {
    const localCouriers: string = localStorage.getItem('crmDeliveryCouriers');
    if (localCouriers) {
      const couriers = this.event.decryptString(localCouriers);
      this.couriers = couriers;
      this.getDefaultOperator();
      return;
    }
    this.appService.getAuth(`deliveries/couriers`).subscribe({
      next: (res) => {
        localStorage.setItem(
          'crmDeliveryCouriers',
          this.event.encryptString(res.data)
        );
        this.couriers = res.data;
        this.getDefaultOperator();
      },
      error: (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        localStorage.removeItem('crmDeliveryCouriers');
      },
    });
  }

  getTransaltions() {
    this.translate
      .get('crmDeliveries.packageInformation')
      .subscribe((text: string) => {
        this.accordionTabs[0].label = text;
        this.tabs.push(text);
        //this.highlightedItems[0].label = text
      });
    this.translate
      .get('crmDeliveries.receiverData')
      .subscribe((text: string) => {
        this.accordionTabs[1].label = text;
        this.tabs.push(text);
      });
    this.translate.get('crmDeliveries.senderData').subscribe((text: string) => {
      this.accordionTabs[2].label = text;
      this.tabs.push(text);
    });
    this.translate
      .get('crmDeliveries.additionalInformation')
      .subscribe((text: string) => {
        this.accordionTabs[3].label = text;
        this.tabs.push(text);
      });

    this.translate
      .get('crmDeliveries.companyAddress')
      .subscribe((text: string) => {
        this.addressTypes[0].label = text;
      });
    this.translate
      .get('crmDeliveries.privateAddress')
      .subscribe((text: string) => {
        this.addressTypes[1].label = text;
      });

    this.translate
      .get('crmDeliveries.receiveAtPoint')
      .subscribe((text: string) => {
        this.deliveryTypes[0].label = text;
      });
    this.translate
      .get('crmDeliveries.courierDelivery')
      .subscribe((text: string) => {
        this.deliveryTypes[1].label = text;
      });

    this.translate
      .get('crmDeliveries.courierReceive')
      .subscribe((text: string) => {
        this.sendingTypes[0].label = text;
      });
    this.translate
      .get('crmDeliveries.sendAtPoint')
      .subscribe((text: string) => {
        this.sendingTypes[1].label = text;
      });
  }

  getReceiveAddress() {
    const deliveryAddressId = this.form.controls['ReceiveAddressId'].value;
    if (deliveryAddressId != null) {
      var operator = this.form.controls['DeliveryOperatorCode'].value;

      this.appService
        .getAuth(
          `deliveries/operators/${operator}/addresses?deliveryAddressId=${deliveryAddressId}`
        )
        .subscribe({
          next: (res) => {
            let obj = res.data.find(
              (i) => i.DeliveryAddressId === deliveryAddressId
            );
            this.sendAddress = this.formatAddress(obj);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
    }
  }

  onSelectCustomerShortcut(e: boolean) {
    setTimeout(() => {
      this.selectCustomerShortcut = e;
      this.cd.detectChanges();
    }, 200);
  }

  getDefaultReceiveAddress() {
    const deliveryAddressId = this.form.controls['ReceiveAddressId'].value;
    if (deliveryAddressId == null) {
      const operator = this.defaultOperator?.DeliveryOperatorCode;

      this.appService
        .getAuth(`deliveries/operators/${operator}/addresses`)
        .subscribe({
          next: (res) => {
            let obj = res.data.find((i) => i.IsDefault);
            this.sendAddress = this.formatAddress(obj);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
    }
  }

  getDefaultOperator(): void {
    if (this.isVisible && this.objectToDelivery) {
      this.appService
        .getAuth(
          `deliveries/operators/DPD/packages?objectId=${this.objectToDelivery.Id}`
        )
        .subscribe({
          next: (res) => {
            this.filteredCouriers = this.couriers.filter(
              (el) =>
                el.DeliveryOperatorCode === res.data[0].DeliveryOperatorCode
            );
            this.courierSizes =
              this.filteredCouriers.find(
                (el) => el.DeliveryCourierId === res.data[0].DeliveryCourierId
              )?.PackageSizes || [];
            this.form.patchValue(res.data[0], { emitEvent: false });
            this.cd.detectChanges();
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
      this.cd.detectChanges();
    }
    if (this.changes.isVisible && this.changes.isVisible.currentValue) {
      if (this.mode === 'add') {
        this.defaultOperator = this.operators.find((el) => el.IsDefault);

        this.getOperatorConfiguration(
          this.defaultOperator.DeliveryOperatorCode
        );

        this.getDefaultReceiveAddress();

        // const setDefaultCourier = (id) => {
        //   this.form.controls.CourierId.setValue(id);
        // }

        const getCouriers = (operator) => {
          this.filteredCouriers = this.couriers.filter(
            (el) => el.DeliveryOperatorCode === operator.DeliveryOperatorCode
          );
          if (this.filteredCouriers.length > 0) {
            this.courierSizes = this.filteredCouriers[0]?.PackageSizes || [];
            this.setDefaultCourier(operator.DeliveryOperatorCode);

            this.form.controls.PackageSize.setValue(
              this.courierSizes[0]?.Value || null
            );
          }
        };

        if (this.defaultOperator) {
          setTimeout(() => {
            this.form.controls.DeliveryOperatorCode.setValue(
              this.defaultOperator.DeliveryOperatorCode
            );
            getCouriers(this.defaultOperator);
            this.cd.detectChanges();
          }, 400);
        }

        if (!this.defaultOperator) {
          this.form.controls.DeliveryOperatorCode.setValue(
            this.operators[0].DeliveryOperatorCode
          );
          getCouriers(this.operators[0]);
          this.cd.detectChanges();
        }
      } else {
        this.getPackage();
      }

      setTimeout(() => {
        if (
          !this.localStorageData?.skipUserGuide &&
          this.isAutoOnboarding &&
          this.event.deviceType === 'desktop' &&
          this.isVisible
        ) {
          this.isGuideActive = true;
          this.cd.detectChanges();
        }
      }, 2000);
    }
    //if (this.changes.isVisible && this.mode === 'edit') {
    //  this.getOperators().then(() => this.getReceiveAddress());
    //}
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue === true) {
      this.printSettings.currentComponentName.set('addCrmDelivery');
      this.changes = changes;
      this.getOperators();
    }

    if (this.mode == 'add') {
      this.title = this.translate.instant('form-commercial-operation.title');
    }

    if (this.mode == 'edit') {
      this.title = this.translate.instant(
        'form-commercial-operation.editInvoice'
      );
    }

    if (this.mode == 'show') {
      this.title = this.translate.instant('buttons.preview');
    }
  }

  getPackage() {
    this.appService
      .getAuth(`deliveries/operators/DPD/packages?objectId=${this.selectedId}`)
      .subscribe({
        next: (res) => {
          this.filteredCouriers = this.couriers.filter(
            (el) => el.DeliveryOperatorCode === res.data[0].DeliveryOperatorCode
          );
          this.courierSizes =
            this.filteredCouriers.find(
              (el) => el.DeliveryCourierId === res.data[0].CourierId
            )?.PackageSizes || [];
          this.form.patchValue(res.data[0], { emitEvent: false });
          if (this.isCopy) {
            this.form.controls['CreateDate'].setValue(new Date());
            this.form.controls['ExternalCode'].setValue('');
            this.form.controls['PackageId'].setValue(0);
            this.form.controls['PackageNumber'].setValue('');
            this.form.controls['ReceiveDate'].setValue(null);
            this.form.controls['ReturnDate'].setValue(null);
            this.form.controls['ShipmentDate'].setValue(null);
          }
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  ngOnDestroy() {
    this.isAutoOnboardingSub?.unsubscribe();
    this.event.onHiddenPopUp();
    this.onClosing.emit(true);
    localStorage.setItem(
      'crmDeliveryAddGuide',
      JSON.stringify(this.localStorageData)
    );
  }

  showDialogOnCancel() {
    if (this.mode === 'show') {
      this.onClosing.emit();
    } else {
      this.isCancelPackage = true;
    }
  }
  onCancel() {
    this.isCancelPackage = false;
    if (this.objectToDelivery) {
      this.appService
        .deleteAuth(
          `deliveries/operators/-1/packages/${this.objectToDelivery.Id}`
        )
        .subscribe(() => {});
    }
    this.onClosing.emit();
  }

  courierChanged(e) {
    if (!e.event) {
      return;
    }

    this.courierSizes =
      this.filteredCouriers.find((el) => el.DeliveryCourierId === e.value)
        ?.PackageSizes || [];
    const courier = this.filteredCouriers.find(
      (el) => el.DeliveryCourierId === e.value
    );
    this.form.controls.Courier.setValue(courier?.Name || null);
    this.form.controls.PackageSize.setValue(this.courierSizes[0]?.Value || '');
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.refreshKeyPressed.next();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.closeDrobBox.next(false);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/paczki-kurierskie');
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.onSave();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTab = 0;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTab = 1;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTab = 2;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTab = 3;
        },
        preventDefault: true,
      },
      {
        key: 'alt + k',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.selectCustomerShortcut = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );

    if (localStorage.getItem('sizePopUpCrmDelivery')) {
      this.widthWindow = JSON.parse(
        localStorage.getItem('sizePopUpCrmDelivery')
      ).widthWindow;
      this.heightWindow = JSON.parse(
        localStorage.getItem('sizePopUpCrmDelivery')
      ).heightWindow;
      this.getAccordionHeight();
    }

    if (localStorage.getItem('crmDeliveryAddGuide')) {
      this.localStorageData = JSON.parse(
        localStorage.getItem('crmDeliveryAddGuide')
      );
    }
  }

  onResizeEnd = () => {
    let size = {
      widthWindow: this.widthWindow,
      heightWindow: this.heightWindow,
    };
    if (this.fullScreen) {
      size = {
        widthWindow: (window.innerWidth - 75).toString(),
        heightWindow: (window.innerHeight - 75).toString(),
      };
      this.heightWindow = (window.innerHeight - 75).toString();
    }
    localStorage.setItem('sizePopUpCrmDelivery', JSON.stringify(size));
    this.getAccordionHeight();
    this.cd.detectChanges();
  };

  validateForm() {
    if (!this.form.controls['ReferenceCode'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[0]];
      this.selectedTab = 0;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noReferenceCode')
      );
      return;
    }

    if (!this.form.controls['DimensionA'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[0]];
      this.selectedTab = 0;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noDimensions')
      );
      return;
    }

    if (!this.form.controls['DimensionB'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[0]];
      this.selectedTab = 0;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noDimensions')
      );
      return;
    }

    if (!this.form.controls['DimensionC'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[0]];
      this.selectedTab = 0;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noDimensions')
      );
      return;
    }

    if (!this.form.controls['Weight'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[0]];
      this.selectedTab = 0;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noWeight')
      );
      return;
    }

    if (!this.form.controls['DeliveryOperatorCode'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[0]];
      this.selectedTab = 0;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noOperator')
      );
      return;
    }

    if (!this.form.controls['CustomerId'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[1]];
      this.selectedTab = 1;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noContraktor')
      );
      return;
    }

    if (!this.form.controls['ReceiverEmail'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[1]];
      this.selectedTab = 1;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noEmail')
      );
      return;
    }

    if (!this.form.controls['ReceiverPhone'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[1]];
      this.selectedTab = 1;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noPhone')
      );
      return;
    }

    if (!this.form.controls['ReceiveAddressId'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[2]];
      this.selectedTab = 2;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noSendAddress')
      );
      return;
    }

    if (!this.form.controls['ShipmentPointCode'].valid) {
      if (this.panelMode === 'accordion')
        this.selectedItems = [this.accordion.items[2]];
      this.selectedTab = 2;
      this.event.showNotification(
        'error',
        this.translate.instant('crmDeliveries.noSendPoint')
      );
      return;
    }
  }

  onSave() {
    this.validateForm();
    this.submitted = true;
    if (this.readOnly || !this.form.valid) {
      return;
    }

    if (!this.form.controls.ReceiveCourier.value) {
      this.form.controls.CourierReceiveDateStart.setValue(null);
      this.form.controls.CourierReceiveDateEnd.setValue(null);
    }

    if (this.mode === 'add' || this.isCopy) {
      this.saveNewForm();
    } else {
      this.saveOldForm();
    }
  }

  checkReceiver() {
    if (!this.form.value.ReceiveCourier) {
      // this.form.value.ReceiveAddressId = null;
    } else {
      this.form.value.ShipmentPointCode = null;
    }
  }

  async saveNewForm() {
    this.checkReceiver();

    this.appService
      .postAuth(
        `deliveries/operators/${this.form.value.DeliveryOperatorCode}/packages`,
        this.form.value
      )
      .subscribe(
        (res) => {
          const printConfig = this.print.currentComponentConfig?.printConfig;
          let actionAfterSave: 'send' | 'print' = null;
          if (printConfig.printLabelAfterSaving) {
            actionAfterSave = 'print';
          } else if (printConfig.sendPackageAfterSaving) {
            actionAfterSave = 'send';
          }
          this.onInserted.emit({
            package: res,
            actionAfterSave: actionAfterSave,
          });
          this.isVisible = false;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  saveOldForm() {
    this.checkReceiver();
    this.appService
      .putAuth(
        `deliveries/operators/${this.form.value.DeliveryOperatorCode}/packages/` +
          this.form.value.PackageId,
        this.form.value
      )
      .subscribe(
        (res) => {
          this.onInserted.emit(res);
          this.isVisible = false;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  clearReceiveAddress() {
    this.sendAddress = '';
    this.form.controls['ReceiveAddressId'].setValue(null);
  }

  visibleChange = () => {
    this.submitted = false;

    setTimeout(() => {
      if (!this.isVisible) {
        this.createForm();
        this.event.closeDrobBox.next(false);
        this.onClosing.emit(true);
      } else {
        this.accordion.instance.expandItem(0);
        this.accordion.instance.expandItem(1);
        this.cd.detectChanges();
        setTimeout(() => {
          this.getAccordionHeight();
        }, 300);
      }
    }, 0);

    this.selectedTab = 0;
    this.cd.detectChanges();
  };

  CourierReceiveDateStart: Date;
  CourierReceiveDateEnd: Date;

  getReceiveDate() {
    let date = new Date();
    this.CourierReceiveDateStart = new Date(
      date.setUTCHours(date.getUTCHours() + 2)
    );
    this.CourierReceiveDateEnd = new Date(
      date.setUTCHours(date.getUTCHours() + 4)
    );
  }

  sendingTypesChange(e: any): void {
    if (e.value) {
      this.form.controls.ReceiveAddressId.setValidators(Validators.required);
      this.form.controls.ShipmentPointCode.clearValidators();
    } else {
      this.form.controls.ReceiveAddressId.clearValidators();
      this.form.controls.ShipmentPointCode.setValidators(Validators.required);
    }
    this.form.controls.ReceiveAddressId.updateValueAndValidity();
    this.form.controls.ShipmentPointCode.updateValueAndValidity();
  }

  createForm = () => {
    this.getReceiveDate();
    // this.setDefaultOperator()
    this.form = this.formBuilder.group({
      AccountId: 0,
      AddressType: '',
      AllegroId: '',
      AllegroLogin: '',
      AutoLabel: 0,
      CashOnDeliveryValue: 0,
      CountryCode: '',
      Courier: '',
      CourierId: 0,
      CourierReceiveDateEnd: this.CourierReceiveDateEnd,
      CourierReceiveDateStart: this.CourierReceiveDateStart,
      CreateDate: new Date(),
      CustomerId: [null, Validators.required],
      CustomerName: '',
      DeliveryOperatorCode: ['', Validators.required],
      DeliveryOperatorName: '',
      Details: '',
      DimensionA: [0, Validators.min(1)],
      DimensionB: [0, Validators.min(1)],
      DimensionC: [0, Validators.min(1)],
      DimensionTotal: '',
      ExternalCode: '',
      InsuranceValue: 0,
      IsOutsize: false,
      IsSelected: false,
      IsUnusual: false,
      PackageId: 0,
      PackageNumber: '',
      PackageSize: '',
      PackageType: '',
      ReceiveAddressId: [null, Validators.required],
      ReceiveCourier: false,
      ReceiveDate: null,
      ReceiveOrderId: 0,
      ReceivePointCode: '',
      ReceiverBuildingNumber: '',
      ReceiverCity: '',
      ReceiverCompany: '',
      ReceiverEmail: ['', Validators.required],
      ReceiverFirstName: '',
      ReceiverLastName: '',
      ReceiverLocalNumber: '',
      ReceiverPhone: ['', Validators.required],
      ReceiverPostalCode: '',
      ReceiverStreet: '',
      ReferenceCode: ['', Validators.required],
      ReturnAddressId: null,
      ReturnDate: null,
      SendAddress: '',
      ShipmentCode: '',
      ShipmentCost: 0,
      ShipmentDate: null,
      ShipmentPointCode: '',
      ShipmentTypeId: '',
      StatusCode: '',
      StatusDescription: '',
      UserId: 0,
      Weight: [0, Validators.min(0.01)],
    });
  };

  selectedCountryChange = (e) => {
    if (e) {
      this.form.controls.CountryCode.setValue(e[0].CountryCode);
    } else if (e === null) {
      this.form.controls.CountryCode.setValue('');
    }
  };

  ///////////////////////

  isTabActive(index: number) {
    return this.selectedItems.findIndex((el) => el.value === index + 1) > -1;
  }

  isTabHighlited(index: number) {
    if (!this.selectedItems.length) {
      return false;
    }
    return this.highlightedItems.findIndex((el) => el === index) > -1;
  }

  operatorConfiguration = [];

  getOperatorConfiguration(code: string) {
    if (!code) {
      return;
    }

    this.appService
      .getAuth(`deliveries/operators/${code}/configuration`)
      .subscribe(
        (res) => {
          this.operatorConfiguration = res.data;
          res.data.forEach((parameter: IDeliveryOperationParameter) => {
            this.runParameter(parameter);
          });
          this.setDefaultCourier(code);
          this.cd.detectChanges();
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  updateTotalDimension() {
    const a = this.form.controls.DimensionA.value;
    const b = this.form.controls.DimensionB.value;
    const c = this.form.controls.DimensionC.value;
    this.form.controls.DimensionTotal.setValue(a + 'x' + b + 'x' + c);
  }

  runParameter(parameter: IDeliveryOperationParameter) {
    switch (parameter.ParameterName) {
      case 'Nadawca email':
        // this.form.controls.
        break;
      case 'Domyślny kurier':
        this.form.controls.Courier.setValue(parameter.ParameterValue);
        break;
      case 'Domyślny wymiar 1 (cm)':
        this.form.controls.DimensionA.setValue(parameter.ParameterValue);
        this.updateTotalDimension();
        break;
      case 'Domyślny wymiar 2 (cm)':
        this.form.controls.DimensionB.setValue(parameter.ParameterValue);
        this.updateTotalDimension();
        break;
      case 'Domyślny wymiar 3 (cm)':
        this.form.controls.DimensionC.setValue(parameter.ParameterValue);
        this.updateTotalDimension();
        break;
      case 'Domyślna waga (kg)':
        this.form.controls.Weight.setValue(parameter.ParameterValue);
        break;
      case 'Paczki odbiera kurier':
        this.form.controls.ReceiveCourier.setValue(
          parameter.ParameterValue === 'Tak' ? true : false
        );
        this.sendingTypesChange(parameter.ParameterValue);
        break;
      case 'Domyślna kwota ubezpieczenia':
        this.form.controls.InsuranceValue.setValue(
          Number(parameter.ParameterValue)
        );
        break;
      default:
        break;
    }
  }

  operatorChanged(e) {
    this.clearReceiveAddress();
    if (!e.event) {
      return;
    }

    if (e.value) {
      this.getOperatorConfiguration(e.value);
      this.form.controls.PackageSize.setValue(null);
      this.getOperatorData(e.value);
    }
  }

  deliveryOperator: IDeliveryOperator;

  getOperatorData(code: string) {
    this.appService
      .getAuth(`deliveries/operators/`, { operatorCode: code })
      .subscribe((res) => {
        this.deliveryOperator = res.data[0];
        if (this.deliveryOperator?.HasPickupAddres) {
          this.sendingTypes[0].disabled = false;
        } else {
          this.sendingTypes[0].disabled = true;
          this.form.controls.ReceiveCourier.setValue(false);
        }
        this.sendingTypesChange(this.form.controls.ReceiveCourier.value);
        this.cd.detectChanges();
      });
  }

  setDefaultCourier(deliveryOperatorCode: string) {
    this.filteredCouriers = this.couriers.filter(
      (el) => el.DeliveryOperatorCode === deliveryOperatorCode
    );
    let defaultCourier = this.operatorConfiguration.find(
      (x) => x.ParameterName == 'Domyślny kurier'
    );
    let defaultCourierId = this.filteredCouriers.find(
      (x) => x.Name == defaultCourier?.ParameterValue
    )?.DeliveryCourierId;
    if (this.filteredCouriers.length) {
      if (defaultCourierId) {
        this.form.controls.CourierId.setValue(defaultCourierId);
      } else {
        this.form.controls.CourierId.setValue(
          this.filteredCouriers[0].DeliveryCourierId
        );
      }

      this.form.controls.Courier.setValue(this.filteredCouriers[0].Name);

      // domyślna wielkość
      this.courierSizes = this.filteredCouriers[0]?.PackageSizes || [];
      this.form.controls.PackageSize.setValue(
        this.courierSizes[0]?.Value || null
      );
    } else {
      this.form.controls.CourierId.setValue(null);
      this.form.controls.Courier.setValue(null);
    }
  }

  isPickingUp: boolean = false;

  onPickingUpChanged() {}

  currentCustomer: any = {};

  onCustomerIdChoosed(e) {
    if (e) {
      this.currentCustomer = e;
      this.form.controls.CustomerId.setValue(e.CustomerId);
      this.form.controls.ReceiverEmail.setValue(e.Email);
      this.form.controls.ReceiverPhone.setValue(e.Phone);
      this.form.controls.ReceiverPostalCode.setValue(e.PostalCode);
      this.form.controls.ReceiverStreet.setValue(e.Street);
      this.form.controls.CountryCode.setValue(e.CountryCode);
      this.form.controls.ReceiverCity.setValue(e.City);
      this.form.controls.AllegroId.setValue(e.AllegroId);
      this.form.controls.AllegroLogin.setValue(e.AllegroLogin);
      this.form.controls.CustomerName.setValue(e.Name);
      this.form.controls.ReceiverCompany.setValue(e.Name);
      this.form.controls.ReceiverEmail.setValue(e.Email);
      this.cd.detectChanges();
    } else if (e === null) {
      this.form.controls.CustomerId.setValue(null);
    }
  }

  toggleTab(index: number) {
    this.accordion.instance.expandItem(index);
    //const visibleIndex = this.selectedItems.findIndex(el=>el.value === tab.value)
    // if(visibleIndex === -1){
    //   this.selectedItems.push(tab)
    // }
    setTimeout(() => {
      this.accordionTitle.forEach((el, i) => {
        if (i === index) {
          (el as any).nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
      // this['accordion' + tab.value].nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  showReceivePointCode: boolean = false;
  showShipmentPointCode: boolean = false;

  onShipmentPointCodeChoosed(e) {
    this.showShipmentPointCode = false;
    this.form.controls.ShipmentPointCode.setValue(e.DeliveryPointCode);
    this.sendAddress = this.formatPointAddress(e);
    this.cd.detectChanges();
  }

  onReceivePointCodeChoosed(e) {
    this.showReceivePointCode = false;
    this.form.controls.ReceivePointCode.setValue(e.DeliveryPointCode);
    this.form.controls.ReceiverCity.setValue(e.City);
    this.form.controls.ReceiverPostalCode.setValue(e.PostalCode);
    this.cd.detectChanges();
  }

  formatPointAddress(e) {
    let address: string = `Kod: ${e.DeliveryPointCode}\n`;
    if (e.PostalCode) {
      address += `${e.PostalCode}`;
      if (e.City) {
        address += ` ${e.City}\n`;
      } else {
        address += '\n';
      }
    }
    if (e.Street) {
      address += `${e.Street}`;
      if (e.BuildingNumer) {
        address += ` ${e.BuildingNumer}\n`;
      } else {
        address += '\n';
      }
    }
    if (e.LocationDescription) {
      address += `${e.LocationDescription}\n`;
    }
    return address;
  }

  showAddress: boolean = false;
  sendingCompanyName: string = '';

  onAddressChoosed(e) {
    this.showAddress = false;
    if (e) {
      this.form.controls.ReceiveAddressId.setValue(e.DeliveryAddressId);
      this.sendAddress = this.formatAddress(e);
      if (e.CompanyName) {
        this.sendingCompanyName = e.CompanyName;
      }
    }
    if (e === null) {
      this.form.controls.ReceiveAddressId.setValue(null);
    }
  }

  formatAddress(e) {
    let address = '';
    if (e) {
      if (e.CompanyName) {
        address += `Firma: ${e.CompanyName}\n`;
      }
      if (e.PostalCode) {
        address += `${e.PostalCode}`;
        if (e.City) {
          address += ` ${e.City}\n`;
        } else {
          address += '\n';
        }
      }
      if (e.Street) {
        address += `${e.Street}`;
        if (e.BuildingNumer) {
          address += ` ${e.BuildingNumer}${
            e.LocalNumber ? '/' + e.LocalNumber : ''
          }\n`;
        } else {
          address += '\n';
        }
      }
      if (e.CountryCode) {
        address += `Kraj: ${e.CountryCode}\n`;
      }
      if (e.Name) {
        address += `Osoba: ${e.Name}\n`;
      }
      if (e.Phone) {
        address += `Tel. ${e.Phone}`;
      }
    }
    return address;
  }

  deliveryTypeValue: number = 1;
  addressTypeValue: number = 0;
  returnPointCode;
  showReturnPointCode: boolean = false;

  onReturnAddressChoosed(e) {
    this.showReturnPointCode = false;
    if (e) {
      this.form.controls.ReturnAddressId.setValue(e.DeliveryAddressId);
      this.returnAddress = this.formatAddress(e);
    }
  }

  copyAddress() {
    this.form.controls.ReturnAddressId.setValue(
      this.form.controls.ReceiveAddressId.value
    );
    this.returnAddress = this.sendAddress;
  }

  sendAddress: string = '';
  returnAddress: string = '';

  getDefaultAddress() {
    if (this.currentCustomer) {
      this.form.controls.ReceiverPhone.setValue(this.currentCustomer.Phone);
      this.form.controls.ReceiverPostalCode.setValue(
        this.currentCustomer.PostalCode
      );
      this.form.controls.ReceiverStreet.setValue(this.currentCustomer.Street);
      this.form.controls.CountryCode.setValue(this.currentCustomer.CountryCode);
      this.form.controls.ReceiverCity.setValue(this.currentCustomer.City);
      this.form.controls.AllegroId.setValue(this.currentCustomer.AllegroId);
      this.form.controls.AllegroLogin.setValue(
        this.currentCustomer.AllegroLogin
      );
      this.form.controls.CustomerName.setValue(this.currentCustomer.Name);
      this.form.controls.ReceiverCompany.setValue(this.currentCustomer.Name);
    }
  }

  accordionHeight = null;

  getAccordionHeight() {
    if (this.fullScreen) {
      this.accordionHeight = `calc(${this.heightWindow}px - 75px)`;
    } else {
      if (typeof this.heightWindow === 'number') {
        this.accordionHeight = `calc(${this.heightWindow}px - 75px)`;
      } else if (
        typeof this.heightWindow === 'string' &&
        this.heightWindow.indexOf('%') > -1
      ) {
        this.accordionHeight = `calc(${this.heightWindow} - 75px)`;
      }
    }
    this.cd.detectChanges();
  }

  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    localStorage.setItem(
      'crmDeliveryAddGuide',
      JSON.stringify(this.localStorageData)
    );
  }

  runGuide() {
    this.isGuideActive = true;
  }

  printConfig: PrintConfig;

  onPanelModeChange(mode: PanelMode) {
    localStorage.setItem(
      'modeAccordionCrmDeliveries',
      String(mode === 'accordion')
    );
  }
}
